import React from 'react'
import { Link } from 'gatsby'

import '../components/globalStyle.css'
import '../components/product.css'

import Header from '../components/header'
import BackButton from '../components/backButton'


const productInfura = () => (
  <div className= "productMetamaskGroup">

    <Header />

    <BackButton />

    <div className="product">

      <div className="productText">

        <h2> Metamask </h2>
        <p className="productInfo">
          <i>
            March 2018 - Sept 2018<br></br>
            Designed w/ Christian Jeria<br></br>
          </i>
        </p>
        <p>
          Metamask is currently the most popular Ethereum wallet with over a million users. It allows users to create and use a single identity to log into any Ethereum based application. Metmamask also stores your Ether (the native currency of Ethereum), and sends and receives it the form of "transactions".
          <br></br>
          <br></br>
          If you're interested in what they do, <a href="https://metamask.io/" target="blank">here is an article you can read</a>
          <br></br>
          <br></br>
          <b> I was working on designing a new transaction flow with better hierarchy of information and clearer actionable items.</b> A new user should feel safe and informed without being overwhelmed, and an experienced user should feel like they have all the minute control that they are used to.
        </p>


        <h4> Creating a Style Guide </h4>

      </div>

      <figure className="productImg">
        <img src={require('../img/metamask/metamaskDesignSystem.png')} />
        <figcaption>I built a style guide in sketch capturing and standardizing the new elements for a more consistent UI.</figcaption>
      </figure>

      <div className="productText">
        <h4>Defining the problems</h4>
      </div>

      <figure className="productImg">
        <img src={require('../img/metamask/metamaskOriginal.svg')} />
        <figcaption>The older design had several usability issues. Most critically it didn't highlight the most important information, the transaction total, and left users feeling confused about whether or not they actually sent anything.</figcaption>
      </figure>

      <div className="productText">
        <h4> Prototyping </h4>
      </div>

      <figure className="productImg">
        <img src={require('../img/metamask/metamaskIteration2.svg')} />
        <figcaption>I sketched and iterated on potential solutions.</figcaption>
      </figure>

      <figure className="productImg">
        <img src={require('../img/metamask/metamaskConfirm.gif')} />
        <figcaption>the final flow for confirming a transaction features clear headers, bold and large transaction totals on top, and signaling to let users know they successfully completed the flow.</figcaption>
      </figure>

      <div className="productText">
        <h4>Moving on</h4>
        <p> I worked on a few other projects with Metamask, like a new GUI for cancelling and retrying a transaction. Reach out to me if you'd like to see them 😄 </p>


      </div>
    </div>
  </div>
)

export default productInfura
